import { template as template_02181e9af9924e6a90a4dc84de9dad58 } from "@ember/template-compiler";
const FKLabel = template_02181e9af9924e6a90a4dc84de9dad58(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
