import { template as template_16b9b77debf24d1b8dfdea6079bfe9c1 } from "@ember/template-compiler";
const FKText = template_16b9b77debf24d1b8dfdea6079bfe9c1(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
